import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const settingsApi = createApi({
	reducerPath: "settingsApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.sa/api/Admin/",
	}),
	tagTypes: ["Settings"],

	endpoints: (builder) => ({
		getSettings: builder.query({
			query: () => ({ url: `setting` }),

			transformResponse: (response) => response.data,
			providesTags: ["Settings"],
		}),

		updateSettings: builder.mutation({
			query: ({ id, body }) => {
				return {
					url: `setting/${id}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Settings"],
		}),
	}),
});

// Export endpoints and hooks
export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi;
