import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const servicesApi = createApi({
	reducerPath: "servicesApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.sa/api/Admin/",
	}),
	tagTypes: ["Services"],

	endpoints: (builder) => ({
		// Get Services Data
		getServices: builder.query({
			query: (args) => ({
				url: `service?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["Services"],
		}),

		searchInServices: builder.mutation({
			query: (arg) => ({
				url: `searchServiceName?query=${arg.query}`,
				method: "GET",
			}),
		}),

		changeStatusService: builder.mutation({
			query: ({ id }) => ({
				url: `service/changeStatus/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Services"],
		}),

		serviceDeleteAll: builder.mutation({
			query: ({ queryParams }) => ({
				url: `servicedeleteall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Services"],
		}),

		getServiceData: builder.query({
			query: ({ showServiceId }) => ({
				url: `service/showDetail/${showServiceId}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "Services", id }],
		}),

		updateService: builder.mutation({
			query: ({ serviceId, body }) => {
				return {
					url: `service/${serviceId}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Services"],
		}),

		addNewService: builder.mutation({
			query: ({ body }) => {
				return {
					url: `service`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Services"],
		}),

		serviceDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `servicedeleteall?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Services"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useGetServicesQuery,
	useGetServiceDataQuery,
	useSearchInServicesMutation,
	useChangeStatusServiceMutation,
	useServiceDeleteAllMutation,
	useUpdateServiceMutation,
	useAddNewServiceMutation,
	useServiceDeleteItemMutation,
} = servicesApi;
