import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const contactUsApi = createApi({
	reducerPath: "contactUsApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.sa/api/Admin/",
	}),

	tagTypes: ["ContactUs"],

	endpoints: (builder) => ({
		atlobhaContactData: builder.query({
			query: () => ({
				url: `atlobhaContact`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["ContactUs"],
		}),

		getAtlobhaContactData: builder.query({
			query: ({ contactUsDetails }) => ({
				url: `atlobhaContact/${contactUsDetails}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "ContactUs", id }],
		}),

		contactChangeStatus: builder.mutation({
			query: ({ id }) => ({
				url: `atlobhaContactChangeStatus/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["ContactUs"],
		}),

		deleteContactItem: builder.mutation({
			query: ({ id }) => ({
				url: `atlobhaContactdeleteall?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["ContactUs"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useAtlobhaContactDataQuery,
	useGetAtlobhaContactDataQuery,
	useContactChangeStatusMutation,
	useDeleteContactItemMutation,
} = contactUsApi;
