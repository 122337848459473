import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const domainOrdersApi = createApi({
	reducerPath: "domainOrdersApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.sa/api/Admin/",
	}),
	tagTypes: ["DomainOrders"],

	endpoints: (builder) => ({
		getDomainOrders: builder.query({
			query: (args) => ({
				url: `WebsiteDomainOrder?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["DomainOrders"],
		}),

		getWebsiteOrderDataById: builder.query({
			query: ({ id }) => ({
				url: `WebsiteDomainOrder/${id}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "DomainOrders", id }],
		}),

		searchInDomainOrders: builder.mutation({
			query: (arg) => ({
				url: `searchOrderDomainName?query=${arg.query}`,
				method: "GET",
			}),
		}),

		rejectDomainOrderById: builder.mutation({
			query: ({ id, body }) => {
				return {
					url: `rejectDomain/${id}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["DomainOrders"],
		}),

		acceptDomainOrderById: builder.mutation({
			query: ({ id, body }) => {
				return {
					url: `acceptDomain/${id}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["DomainOrders"],
		}),

		domainOrdersDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `WebsiteDomainOrderdeleteall?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["DomainOrders"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useGetDomainOrdersQuery,
	useGetWebsiteOrderDataByIdQuery,
	useSearchInDomainOrdersMutation,
	useAcceptDomainOrderByIdMutation,
	useRejectDomainOrderByIdMutation,
	useDomainOrdersDeleteItemMutation,
} = domainOrdersApi;
