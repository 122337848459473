import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const rolesApi = createApi({
	reducerPath: "rolesApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.sa/api/Admin/",
	}),
	tagTypes: ["Roles"],

	endpoints: (builder) => ({
		getRoles: builder.query({
			query: () => ({ url: `roles` }),

			transformResponse: (response) => response.data,
			providesTags: ["Roles"],
		}),

		getRoleData: builder.query({
			query: ({ roleId }) => ({
				url: `roles/${roleId}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "Roles", id }],
		}),

		addNewRole: builder.mutation({
			query: ({ body }) => {
				return {
					url: `roles`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Roles"],
		}),

		updateRoleItem: builder.mutation({
			query: ({ roleId, body }) => {
				return {
					url: `roles/${roleId}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Roles"],
		}),

		deleteRoleId: builder.mutation({
			query: ({ id }) => ({
				url: `roles/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Roles"],
		}),

		getPermissions: builder.query({
			query: () => ({ url: `permissions` }),

			transformResponse: (response) => response.data,
			providesTags: ["Permissions"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useGetRolesQuery,
	useGetRoleDataQuery,
	useGetPermissionsQuery,
	useAddNewRoleMutation,
	useUpdateRoleItemMutation,
	useDeleteRoleIdMutation,
} = rolesApi;
