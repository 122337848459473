import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const citiesApi = createApi({
	reducerPath: "citiesApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.sa/api/Admin/",
	}),
	tagTypes: ["Cities"],

	endpoints: (builder) => ({
		getCities: builder.query({
			query: (args) => ({
				url: `city?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["Cities"],
		}),

		getCityData: builder.query({
			query: ({ showCityId }) => ({
				url: `city/${showCityId}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "Cities", id }],
		}),

		searchInCities: builder.mutation({
			query: (arg) => ({
				url: `searchCity?query=${arg.query}`,
				method: "GET",
			}),
		}),

		updateCity: builder.mutation({
			query: ({ cityId, body }) => {
				return {
					url: `city/${cityId}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Cities"],
		}),

		addNewCity: builder.mutation({
			query: ({ body }) => {
				return {
					url: `city`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Cities"],
		}),

		citiesDeleteAllByParams: builder.mutation({
			query: ({ queryParams }) => ({
				url: `cityedeleteall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Cities"],
		}),

		citiesDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `cityedeleteall?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Cities"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useGetCitiesQuery,
	useGetCityDataQuery,
	useSearchInCitiesMutation,
	useUpdateCityMutation,
	useAddNewCityMutation,
	useCitiesDeleteAllByParamsMutation,
	useCitiesDeleteItemMutation,
} = citiesApi;
