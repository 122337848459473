import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const talabatEtlobhaApi = createApi({
	reducerPath: "talabatEtlobhaApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.sa/api/Admin/",
	}),
	tagTypes: ["ImportOrders"],

	endpoints: (builder) => ({
		// Get Etlobha Products Data
		importOrdersData: builder.query({
			query: (args) => ({
				url: `importOrders?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["ImportOrders"],
		}),

		// Search in Import Orders
		searchInImportOrders: builder.mutation({
			query: (arg) => ({
				url: `searchOrder?query=${arg.query}`,
				method: "GET",
			}),
		}),

		// Delete All Products
		importOrdersDeleteAll: builder.mutation({
			query: ({ queryParams }) => ({
				url: `importordersdeleteall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["ImportOrders"],
		}),

		// Show Import Order Data
		showImportOrderData: builder.query({
			query: ({ souqAtlbhaOrderId }) => ({
				url: `showImportOrder/${souqAtlbhaOrderId}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "ImportOrders", id }],
		}),

		// Update Import Order Data
		updateImportOrder: builder.mutation({
			query: ({ souqAtlbhaOrderId, body }) => {
				return {
					url: `updateImportOrder/${souqAtlbhaOrderId}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["ImportOrders"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useImportOrdersDataQuery,
	useShowImportOrderDataQuery,
	useSearchInImportOrdersMutation,
	useImportOrdersDeleteAllMutation,
	useUpdateImportOrderMutation,
} = talabatEtlobhaApi;
